import { useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useStyles } from '../tabs/TabsStyle'
import {
    Box,
    Grid,
    Stack,
    Button,
    Typography
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import CircleIcon from '@mui/icons-material/Circle';
import {
    CustomLoadingOverlay,
    GenericHeaderActions
} from '../index';
// import registerGrayIcon from '../../assets/registerAsignGray.png'
// import validationGrayIcon from '../../assets/asegurabilityValidationGray.png'
// import documentalLoadGrayIcon from '../../assets/documentalLoadGray.png'
// import hsbcValidationGrayIcon from '../../assets/hscbValidationGray.png'
// import registerGreenIcon from '../../assets/registerAsignGreen.png'
// import validationGreenIcon from '../../assets/asegurabilityValidationGreen.png'
// import documentalLoadGreenIcon from '../../assets/documentalLoadGreen.png'
// import hsbcValidationGreenIcon from '../../assets/hscbValidationGreen.png'
// import registerOrangeIcon from '../../assets/registerAsignOrange.png'
// import validationOrangeIcon from '../../assets/asegurabilityValidationOrange.png'
// import documentalLoadOrangeIcon from '../../assets/documentalLoadOrange.png'
// import hsbcValidationOrangeIcon from '../../assets/hscbValidationOrange.png'
import registerIcon from '../../assets/registro.svg'
import verifyIcon from '../../assets/asegurabilidad.svg'
import loadIcon from '../../assets/carga.svg'
import dictamenIcon from '../../assets/dictamen.svg'
import validacionIcon from '../../assets/validacion.svg'
import pagoIcon from '../../assets/pago.svg'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { useDispatch, useSelector } from 'react-redux';
import { ticketActions } from '../../store/actions';
import { useEffect, useState } from 'react';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white, // Color de la flecha blanca
        dropShadow: '#fff;',
    },
}));


export const TicketStatusView = (props) => {
    const STYLE_PROGRESS_CONTAINER = { height: '2px', width: '100%', margin: 'auto' }
    const STYLE_PROGRESS = (status) => {
        return { height: '2px', width: '100%', backgroundColor: status == "none" ? 'none' : 'gray' }
    }
    const STYLE_CIRCLE_ICON = (step) => {
        return {
            border: `3px solid ${step == 'Aceptado' ? '#68A913'
                : step == 'Pendiente' ? '#FFD233'
                    : step == 'No exitoso' ? '#DB0011'
                        : step == 'En Revision' ? '#3F8BC3'
                            : 'gray'}`, borderRadius: '100px', padding: '20px'
        }
    }
    const STYLE_ICON_TICKETS = { width: '180px'/*, marginLeft: '25%', marginRight: '100px' */ }
    const STYLE_STEPS_TICKETS = (step) => {
        const style = step.Estatus === 'Aceptado' ? { color: '#68A913', marginRight: '8px' }
            : step.Estatus === 'Pendiente' ? { color: '#FFD233', marginRight: '8px' }
                : step.Estatus === 'No exitoso' ? { color: '#DB0011', marginRight: '8px' }
                    : step.Estatus === 'En Revision' ? { color: '#3F8BC3', marginRight: '8px' }
                        : { color: 'black', marginRight: '8px' }
        return style
    }

    const theme = useTheme();
    const localStyles = useStyles(theme)
    const dispatch = useDispatch()
    const { id } = useParams()

    const ticketsInfo = useSelector(state => state.TicketsBySearch)

    const [ticket, setTicket] = useState([])
    const [groupStatusSize, setgroupStatusSize] = useState(0)

    useEffect(() => {
        getTicketsStatus()
    }, [])

    useEffect(() => {
        if (ticketsInfo?.items != undefined) {
            setTicket(ticketsInfo?.items)
        }
        setgroupStatusSize(props?.data.length)
    }, [ticketsInfo])


    const getTicketsStatus = () => {
        const objSearch = {
            "File_Id": props.folio,
            "Busqueda": ""
        }
        dispatch(ticketActions.GetTicketsBySearch(objSearch))
    }

    const currentStage = () => {
        let current = props?.data[0]
        for (let i = 0; i < props?.data?.length; i++) {
            // const currentStage = props?.data[i]?.EstatusProcesos?.some(step => step.Estatus === 'Aceptado' || step.Estatus === 'Pendiente')
            const currentStage = props?.data[i]?.EstatusProcesos?.some(step => step.Estatus === 'Aceptado')
            if (currentStage == false) {
                current = i > 0 ? props?.data[i - 1] : 0
                break
            }
        }
        return current?.GroupName
    }

    const allRdy = (stage) => {
        const current = currentStage()
        if (stage?.GroupName === current) {
            return 'Pendiente'
        }
        // const asd = stage?.EstatusProcesos?.some(step => step.Estatus === 'Aceptado' || step.Estatus === 'Pendiente')
        const asd = stage?.EstatusProcesos?.some(step => step.Estatus === 'Aceptado')
        // console.log('ASD: ', asd)
        if (asd) {
            return 'Aceptado'
        } else {
            return ''
        }

    }

    return (
        <div>
            <div className={localStyles.scroll}>
                <Stack display={'flex'} direction={'row'} alignItems={'center'} justifyContent={'start'} sx={{ mt: 2, ml: 3 }}>
                    {/* <Box sx={{ mb: 1, mr: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => { console.log('Si se puede adsadasda') }}><ArrowBackIcon /></Box> */}
                    <GenericHeaderActions LabelTitle="Estatus del proceso"
                        onClickConfirm={props.onClickReturn}
                        CustomButton={
                            <Box position={'relative'} sx={{ height: 45, minWidth: 220, maxWidth: 228 }}>
                                {ticket?.length > 0 ? <Box sx={{
                                    position: 'absolute',
                                    top: -10,
                                    right: 20,
                                    zIndex: 1,
                                    border: '2px solid red',
                                    borderRadius: '15px',
                                    backgroundColor: '#fff',
                                    width: '30px', height: '30px',
                                    textAlign: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Stack display={'flex'} direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: '3px', color: 'red', userSelect: 'none' }}>
                                        {ticket?.length}
                                    </Stack>
                                </Box> : null}
                                <Button
                                    variant={props.ConfirmButtonVariant ?? "contained"}
                                    sx={{ height: 45, minWidth: 220, maxWidth: 228 }}
                                    onClick={props.onClickReturn}
                                >
                                    Seguimientos
                                </Button>
                            </Box>
                        }
                    />
                </Stack>
                <Stack display={'flex'} direction={'row'} alignItems={'center'} justifyContent={'start'} sx={{ mt: 1, ml: 3 }}>
                    <Typography sx={{ color: '#68A913', marginRight: '8px' }}><CircleIcon fontSize='medium' sx={{ pr: 1 }} /><span style={{ color: 'black' }}>Aceptado</span></Typography>
                    <Typography sx={{ color: '#FFD233', marginRight: '8px' }}><CircleIcon fontSize='medium' sx={{ pr: 1 }} /><span style={{ color: 'black' }}>Pendiente</span></Typography>
                    <Typography sx={{ color: '#DB0022', marginRight: '8px' }}><CircleIcon fontSize='medium' sx={{ pr: 1 }} /><span style={{ color: 'black' }}>No exitoso</span></Typography>
                    <Typography sx={{ color: '#3F8BC3', marginRight: '8px' }}><CircleIcon fontSize='medium' sx={{ pr: 1 }} /><span style={{ color: 'black' }}>En revisión</span></Typography>
                </Stack>

                {ticketsInfo?.loading ?
                    <div className={localStyles.spinSection}>
                        <CustomLoadingOverlay
                            active={ticketsInfo?.loading} text="Espera un momento por favor..."
                        />
                    </div> :
                    <Stack display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'start'} sx={{ height: '80%', mt: 4 }}>
                        <Grid display={'flex'} direction={'row'} alignItems={'start'} justifyContent={'center'} sx={{ width: '90%', flexFlow: 'row nowrap' }}>

                            {!!props?.data && props?.data?.map((group, index) => {
                                const lastGroup = index + 1 == groupStatusSize
                                return (
                                    <Grid display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'start'} >
                                        <Stack display={'flex'} direction={'row'} sx={{ width: '100%' }}>
                                            <div style={STYLE_PROGRESS_CONTAINER}><div style={STYLE_PROGRESS(index == 0 ? 'none' : 'completed')}></div></div>
                                            <Box sx={STYLE_CIRCLE_ICON(allRdy(group))}>
                                                <img src={registerIcon} style={STYLE_ICON_TICKETS} />
                                            </Box>
                                            <div style={STYLE_PROGRESS_CONTAINER}><div style={STYLE_PROGRESS(lastGroup ? 'none' : 'completed')}></div></div>
                                        </Stack>
                                        <p style={{ marginTop: '30px', fontWeight: 'bold !important', textAlign: 'center' }}>{group?.GroupName}</p>
                                    </Grid>
                                )
                            })}

                        </Grid>

                        <Grid container display={'flex'} direction={'row'} alignItems={'flex-start'} justifyContent={'center'} sx={{ width: '90%', mt: 3 }}>
                            <Grid item xs={2} display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ pl: 1 }}>
                                {props.data[0]?.EstatusProcesos?.map((step, index) => {
                                    return (
                                        <Typography sx={STYLE_STEPS_TICKETS(step)}>
                                            <LightTooltip title={step.ProcessName} placement="right-start" arrow><CircleIcon fontSize='medium' sx={{ pr: 1 }} /></LightTooltip>
                                        </Typography>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={2} display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ pl: 1 }}>
                                {props.data[1]?.EstatusProcesos?.map((step, index) => {
                                    return (
                                        <Typography sx={STYLE_STEPS_TICKETS(step)}>
                                            <LightTooltip title={step.ProcessName} placement="right-start" arrow><CircleIcon fontSize='medium' sx={{ pr: 1 }} /></LightTooltip>
                                        </Typography>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={2} display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ pl: 1 }}>
                                {props.data[2]?.EstatusProcesos?.map((step, index) => {
                                    return (
                                        <Typography sx={STYLE_STEPS_TICKETS(step)}>
                                            <LightTooltip title={step.ProcessName} placement="right-start" arrow><CircleIcon fontSize='medium' sx={{ pr: 1 }} /></LightTooltip>
                                        </Typography>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={2} display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ pl: 1 }}>
                                {props.data[4]?.EstatusProcesos?.map((step, index) => {
                                    return (
                                        <Typography sx={STYLE_STEPS_TICKETS(step)}>
                                            <LightTooltip title={step.ProcessName} placement="right-start" arrow><CircleIcon fontSize='medium' sx={{ pr: 1 }} /></LightTooltip>
                                        </Typography>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={2} display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ pl: 1 }}>
                                {props.data[3]?.EstatusProcesos?.map((step, index) => {
                                    return (
                                        <Typography sx={STYLE_STEPS_TICKETS(step)}>
                                            <LightTooltip title={step.ProcessName} placement="right-start" arrow><CircleIcon fontSize='medium' sx={{ pr: 1 }} /></LightTooltip>
                                        </Typography>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={2} display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ pl: 1 }}>
                                {props.data[5]?.EstatusProcesos?.map((step, index) => {
                                    return (
                                        <Typography sx={STYLE_STEPS_TICKETS(step)}>
                                            <LightTooltip title={step.ProcessName} placement="right-start" arrow><CircleIcon fontSize='medium' sx={{ pr: 1 }} /></LightTooltip>
                                        </Typography>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Stack>
                }
            </div>
        </div>
    )
}